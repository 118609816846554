import React from 'react'
import { Link } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'

import '../../styles/services.css'
import { FaLaptop, FaHandshake, FaCubes } from "react-icons/fa";
import { IoArrowRedoCircleOutline } from "react-icons/io5";

const Services = () => {
  const { ref: aRef, inView: aView} = useInView();
  const { ref: bRef, inView: bView} = useInView();
  const { ref: cRef, inView: cView} = useInView();
  const { ref: dRef, inView: dView} = useInView();
  const { ref: eRef, inView: eView} = useInView();

  return (
    <>
      <div className="services" >
        <div className="cont">
          <h2 className={"afted anihideLeft" + (aView ? " anishowRight" : " ")} style={{ "--i" : "2"}} ref={aRef}>Our Services</h2>
          <p className={"anihideDown" + (bView ? " anishowUp" : " ")} style={{ "--i" : "4"}} ref={bRef}>Our responsibility and our ethics are to meet the different needs while preserving the long-term interest of all stakeholders.</p>
          <ul>
            <li className={"fes anihideVis" + (cView ? " anishowVis" : " ")} style={{ "--i" : "6"}} ref={cRef}>
              <FaLaptop />
              <h2>E-Solutions <br /> Account</h2>
              <p>Control your finances with free access to your money 24 hours a day. Bank accounts also offering investments, insurance and a payday advance.</p>
              <Link to={'/more'}>More Information <IoArrowRedoCircleOutline /></Link>
            </li>
            <li className={"sec anihideVis" + (dView ? " anishowVis" : " ")} style={{ "--i" : "7.5"}} ref={dRef}>
              <FaHandshake />
              <h2> Savings <br /> E-Solutions</h2>
              <p>Control your finances with free access to your money 24 hours a day. Bank accounts also offering investments, insurance and a payday advance.</p>
              <Link to={'/more'}>More Information <IoArrowRedoCircleOutline /></Link>
            </li>
            <li className={"tord anihideVis" + (eView ? " anishowVis" : " ")} style={{ "--i" : "9"}} ref={eRef}>
              <FaCubes />
              <h2> Optimal <br /> Protection</h2>
              <p>Protect your family, for life as well as your money with the assurance of financial security, a choice of customized formulas and appropriate coverage levels. </p>
              <Link to={'/more'}>More Information <IoArrowRedoCircleOutline /></Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Services