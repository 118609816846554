import React, { Component } from 'react'

export class Trad extends Component {
    constructor(props) {
      super(props);
      this.myRef = React.createRef();
    }
  
    componentDidMount() {
      const script = document.createElement('script');
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-forex-cross-rates.js'
      script.async = false;
      script.innerHTML = JSON.stringify({
        "currencies": [
          "EUR",
          "USD",
          "JPY",
          "GBP",
          "CHF",
          "AUD",
          "CAD",
          "NZD"
        ],
        "isTransparent": false,
        "colorTheme": "dark",
        "locale": "en"
      })
      this.myRef.current.appendChild(script);
    }
  
    render() {
      return(
      <div className="tradingview-widget-container trad_cont" ref={this.myRef}>
          <div className="tradingview-widget-container__widget"></div>    
      </div>
      );
    }
  }

export default Trad