import React from 'react'
import { useInView } from 'react-intersection-observer'

import '../../styles/contactSupport.css'
const ContactSupport = () => {
  const { ref: aRef, inView: aView} = useInView();
  const { ref: bRef, inView: bView} = useInView();
  const { ref: cRef, inView: cView} = useInView();
  const { ref: dRef, inView: dView} = useInView();
  const { ref: eRef, inView: eView} = useInView();
  const { ref: fRef, inView: fView} = useInView();
  const { ref: gRef, inView: gView} = useInView();
  const { ref: hRef, inView: hView} = useInView();
  return (
    <>
      <div className="contact-support">
        <div className="cont">
          <h2 className={"afted anihideLeft" + (aView ? " anishowRight" : " ")} style={{ "--i" : "4"}} ref={aRef} >Need of <br />   information ?</h2>
          <h5 className={"anihideLeft" + (bView ? " anishowRight" : " ")} style={{ "--i" : "6"}} ref={bRef}>Our financial advisors always help you.</h5>
          <form action="#" className='filler'>
            <input type="text" name='FullName' id='FullName' placeholder='Full Name' className={ "anihideLeft" + (cView ? " anishowRight" : " ")} style={{ "--i" : "7"}} ref={cRef}/>
            <input type="email" name="email" id="email" placeholder='Your Email' className={ "anihideRight" + (dView ? " anishowLeft" : " ")} style={{ "--i" : "7.5"}} ref={dRef}/>
            <input type="number" name="phone" id="phone" placeholder='Phone' className={ "anihideLeft" + (eView ? " anishowRight" : " ")} style={{ "--i" : "8"}} ref={eRef}/>
            <input type="text" name="subject" id="subject" placeholder='subject' className={ "anihideRight" + (fView ? " anishowLeft" : " ")} style={{ "--i" : "8.5"}} ref={fRef}/>
            <textarea name="message" id="message" placeholder='message' className={ "anihideIn" + (gView ? " anishowOut" : " ")} style={{ "--i" : "9"}} ref={gRef}></textarea>
            <button className={"hoved anihideIn" + (hView ? " anishowOut" : " ")} style={{ "--i" : "9.5"}} ref={hRef}>To send</button>
          </form>
        </div>
      </div>
    </>
  )
}

export default ContactSupport