import React from 'react'
import { Link } from 'react-router-dom'
import {SiCommerzbank } from 'react-icons/si'

import IMGS from '../assets/index'

const Logo = () => {
    return (
        <>
            <div className="foot-nav">
                <div className="logo"><Link to="/"><img src={IMGS.citifirst} alt="city first bank" /></Link><h5 className='wht'>Online Bank</h5></div>
            </div>
        </>
    )
}

export default Logo