import React, { useState } from 'react'
import { Link } from "react-router-dom";

import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import Logo from '../../others/Logo';

const MobileNav = () => {
  const [menu, setMenu] = useState(false);
  return (
    <>
      <header className="headed mobile">
        <div className="main-nav">
          <Logo />
          <div className="nav-switch" onClick={(e) => setMenu(!menu)}>
            {menu ? <h2><AiOutlineClose /></h2> : <h2>< AiOutlineMenu /></h2>}
          </div>
        </div>
        <ul className={'nav-mobile-menu' + (menu ? " activeMobileNav" : " ")}>
          <li><Link to={`/`} onClick={(e) => setMenu(false)}> Home </Link></li>
          <li><Link to={`/sign-in`} onClick={(e) => setMenu(false)}> Account </Link></li>
          <li><Link to={`/savings`} onClick={(e) => setMenu(false)}> Savings </Link></li>
          <li><Link to={`/insurance`} onClick={(e) => setMenu(false)}> Insurance </Link></li>
          <li><Link to={`/contact-us`} onClick={(e) => setMenu(false)}> Contact us </Link></li>
        </ul>
      </header>
    </>
  )
}

export default MobileNav