import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";

import "../styles/header.css";
import MobileNav from "./MobileNav";
import TickerTapeWidget from "../../livetrade/templates/TickerTapeWidget";

import { getAccount } from "../../../database/actions/account";

const Header = ({ liveC, setLiveC }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const { account, isLoading } = useSelector((state) => state.accounts);
  const [unread, setUnread] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  const logout = () => {
    navigate(`/sign-in`);
    dispatch({ type: 'LOGOUT' })
    setUser(null)
  }

  useEffect(() => {
    if(!user){
      navigate(`/sign-in`)
    }
  }, [user, navigate])
  
  useEffect(() => {
    const pathname = location.pathname.split("/");
    if(pathname.includes('liveChat')){
      setLiveC(true)
    } else {
      setLiveC(false)
    }
  }, [location.pathname])

  useEffect(() => {
    const token = user?.token

    setUser(JSON.parse(localStorage.getItem('profile')))
  }, [location]);

  useEffect(() => {
    if (user) {
      dispatch(getAccount(user?.result?._id));
    }
  }, [dispatch, user])

  useEffect(() => {
    if (account) {
      const prev = account?.logs?.support
      const msgs = account?.liveChats
      const sendersMsg = msgs.filter((item) => item.sender === "Support")
      const current = sendersMsg.length
      const unread = Number(current) - Number(prev)
      setUnread(unread)
    }
  }, [account])
  return (
    <>
    <MobileNav logout={logout} user={user} unread={unread} />
    <TickerTapeWidget />
    </>
  );
};

export default Header;
