export default (state = { isLoading: false, accounts: [], isSending: false }, action) => {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isLoading: true };
    case 'STOP_LOADING':
      return { ...state, isLoading: false };
    case 'START_SENDING':
      return { ...state, isSending: true };
    case 'STOP_SENDING':
      return { ...state, isSending: false };
    case 'FETCH_ACCOUNTS':
      return { ...state, accounts: action.payload };
    case 'FETCH_ACCOUNT':
      return { ...state, account: action.payload };
    case 'UPDATEPROFILE':
      return {
        ...state,
        accounts: state.accounts.map((account) => {
          if (account._id === action.payload._id) {
            return action.payload;
          }
          return account
        })
      };
    case 'UPDATEPASSWORD':
      return {
        ...state,
        accounts: state.accounts.map((account) => {
          if (account._id === action.payload._id) {
            return action.payload;
          }
          return account
        })
      };
    case 'TRANSACT':
      return {
        ...state,
        accounts: state.accounts.map((account) => {
          if (account._id === action.payload._id) {
            return action.payload;
          }
          return account
        })
      };
    default:
      return state;
  }
}