import React from 'react'
import { Link } from "react-router-dom";

import '../../styles/banner.css'
const Banners = () => {
	return (
		<div className="banned">
			<div className="banned-container">
				<ul>
					<li className='beforeWas'>
						<div className="bnnr1">
							<h2 >Your account <br /> <span>E-Solutions </span> it's free</h2>
							<h4 >Open your account with ease and select your card according to your needs! it's easy fast and secure.</h4>
							<div className="banned-links">
								<Link to={`/sign-in`}>Register</Link>
								<Link to={`/sign-in`}>Online Banking</Link>
							</div>
						</div>
						<div className="bnnr2">
							<h2>Do you need  <br /> <span>Financing </span> ?</h2>
							<h4>With CityFirst Internet Banking, you have the freedom to borrow and repay flexibly.</h4>
							<div className="banned-links">
								<Link to={`/sign-in`}>Get Loan</Link>
								<Link to={`/sign-in`}>Log In</Link>
							</div>
						</div>
						<div className="bnnr3">
							<h2>you don't  <br /> <span>need to  </span>  move</h2>
							<h4>Our online banking services are even easier to use. Register Now - It's easy and fast!</h4>
							<div className="banned-links">
								<Link to={`/sign-in`}>More Information</Link>
								<Link to={`/sign-in`}>Log In</Link>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default Banners