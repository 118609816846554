import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { withdraw } from '../../../database/actions/account';


import '../styles/withdrawal.css'

const Withdrawal = () => {
  const { account } = useSelector((state) => state.accounts);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const [withdrawalValue, setwithdrawalValue] = useState({ recName: '', RecAdd: '', wallet: '', total: '', amount: '', charge: '', payable: '', text: '' });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let percent = 0;
    let payable = 0;
    if (withdrawalValue.amount < 5) {
      setwithdrawalValue({ ...withdrawalValue, text: 'Minimum Withdrawal: $5' });
    }
    else if (withdrawalValue.amount > 5) {
      percent = (withdrawalValue.amount * 0.0125).toFixed(3);
      payable = Number(withdrawalValue.amount) + Number(percent);
      setwithdrawalValue({ ...withdrawalValue, total: payable, charge: percent, payable: payable });
    }
  }, [withdrawalValue.amount]);

  const handleWithdrawal = async (e) => {
    e.preventDefault();

    let balance = Number(account?.transaction?.balance?.mainAccount)
    balance = Number(account?.transaction?.balance?.mainAccount) - Number(withdrawalValue.payable)

    const formData = {
      "recName": `${withdrawalValue.recName}`,
      "recBank": `Fine Line Bank`,
      "accNum": `${user.result._id}`,
      "address": `${withdrawalValue.RecAdd}`,
      "alertType": `Debit`,
      "amount": Number(withdrawalValue.amount),
      "charge": `+ ${withdrawalValue.charge}`,
      "total": Number(withdrawalValue.total),
      "details": `Withdrawal via ${withdrawalValue.wallet}`,
      "status": `Pending`,
    }

    if (account?.transaction?.balance?.mainAccount < 5) {
      setwithdrawalValue({ text: "Minimum withdrawal is $5" });
    }
    else if (account?.transaction?.balance?.mainAccount < withdrawalValue.payable) {
      setwithdrawalValue({ ...withdrawalValue, text: "Not Sufficient Balance" });
    }
    else if (account?.transaction?.balance?.mainAccount > withdrawalValue.payable) {
      setwithdrawalValue({ ...withdrawalValue, text: " " });
      dispatch(withdraw(user.result._id, formData, navigate, user));
    }
  }

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <>
      <div className="withdraw">
        <div className="withdraw-header">
          <h2>Withdrawal</h2>
        </div>
        <div className="withdraw-container">
          <div className="withdraw-form">
            <form onSubmit={handleWithdrawal}>
              <div className="form-cont transfer-input">
                <input type="text" name="recName" id="recName" value={withdrawalValue.recName} onChange={(e) => setwithdrawalValue({ ...withdrawalValue, recName: e.target.value })} required/>
                <div className="underline"></div>
                <label htmlFor="recName">Recipient`s Name</label>
              </div>
              <div className="form-cont transfer-input">
              <input type='text' name="RecAdd" id="RecAdd" value={withdrawalValue.RecAdd} onChange={(e) => setwithdrawalValue({ ...withdrawalValue, RecAdd: e.target.value })} required />
              <div className="underline"></div>
              <label htmlFor="RecAdd">Recipient Address </label>
            </div>
              <div className="form-cont transfer-input">
                <input type="number" name="amount" id="amount" value={withdrawalValue.amount} onChange={(e) => setwithdrawalValue({ ...withdrawalValue, amount: e.target.value })} required />
                <div className="underline"></div>
                <label htmlFor="amount">Amount</label>
              </div>
              <select name="wallet" id="wallet" value={withdrawalValue.wallet} onChange={(e) => setwithdrawalValue({ ...withdrawalValue, wallet: e.target.value })} required>
                <option value="Cash Mailing">Cash Mailing</option>
                <option value="Wire Transfer">Wire Transfer</option>
              </select>
              <div className="total">
                <h3>{withdrawalValue.total ? USDollar.format(withdrawalValue.total) : '0'} <span> Total: +1.25% </span> </h3>

              </div>
              <button type="submit">Request</button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Withdrawal