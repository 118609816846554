import React, { Component } from 'react'
import '../styles/analysisw.css'

export default class AnalysisWidget extends Component {
  constructor(props) {
    super(props);
    this._ref = React.createRef();
  }
  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js'
    script.async = true;
    script.innerHTML = JSON.stringify({
      "interval": "1m",
      "isTransparent": true,
      "symbol": "COINBASE:BTCUSD",
      "showIntervalTabs": true,
      "locale": "en",
      "colorTheme": "dark"
    })
    this._ref.current.appendChild(script);
  }
  render() {
    return (
      <>
        <div className="tradingview-widget-container trad_cont" ref={this._ref}>
          <div className="tradingview-widget-container__widget"></div>
        </div>
      </>
    );
  }
}