import axios from 'axios';

const API = axios.create({ baseURL: 'https://twosecsbank.onrender.com' });

API.interceptors.request.use((req) => {
    if(localStorage.getItem('profile')){
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    }
    return req;
})

export const fetchAccounts = () => API.get('/account');
export const fetchAccount = (id) => API.get(`/account/${id}`);
export const updateProfile = (id, formData ) => API.patch(`/account/update/${id}`, formData);
export const updatePassword = (id, formData ) => API.patch(`/account/changepassword/${id}`, formData);
export const transfer = (id, formData ) => API.patch(`/account/transfer/${id}`, formData);
export const withdraw = (id, formData ) => API.patch(`/account/withdraw/${id}`, formData);

export const requestCard = (id, formData ) => API.post(`/account/requestCard/${id}`, formData);
export const msg = (msgDets, userID) => API.post(`/account/sendMsg/${userID}`, {msgDets});
export const updateMsgCounterSupport = (current, userID) => API.patch(`/account/${userID}/updateMsgCounterSupport`, {current});

export const signIn = (formData) => API.post('/user/signin', formData);
export const signUp = (formData) => API.post('/user/signup', formData);