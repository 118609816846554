import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { updatePassword } from '../../../database/actions/account';

import '../styles/changepassword.css'

const ChangePassword = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch()
  const navigate = useNavigate();
  
  const initialState = {
    newPass: '',
    newPassA: '',
    oldPass: '',
    text: ''
  }
  const [formData, setFormData] = useState(initialState);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(formData.newPass !== formData.newPassA){
      setFormData({...formData, text: "Password don't match"})
    } else{
      setFormData({...formData})
      dispatch(updatePassword(user.result._id, formData, navigate));
    }
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
	return (
		<>
		<div className="cpassword">
			<div className="passwod-header">
				<h2>Change Password <span> {formData.text}</span></h2>
			</div>
			<div className="trans-cont">
			<form onSubmit={handleSubmit}>
          <div className="form-cont transfer-input">
            <input type="password" name="newPass" id="newPass" onChange={handleChange} required/>
            <div className="underline"></div>
            <label htmlFor="newPass">New Password</label>
          </div>
          <div className="form-cont transfer-input">
            <input type='password' name="newPassA" id="newPassA" onChange={handleChange} required/>
            <div className="underline"></div>
            <label htmlFor="newPassA">Retype Password </label>
          </div>
          <div className="form-cont transfer-input">
            <input type='password' name="oldPass" id="oldPass" onChange={handleChange} required/>
            <div className="underline"></div>
            <label htmlFor="oldPass">Old Password</label>
          </div>
          
          <button type='submit'>Update</button>
        </form>
      </div>
			</div></>
	)
}

export default ChangePassword