import React from 'react'

const Loan = () => {
  return (
    <div className="profile">
      <div className="cont">
        <div className="headt">
          <h2>Profile</h2>
        </div>
      </div>
    </div>
  )
}

export default Loan