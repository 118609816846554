import React from 'react'
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer'

import { FiFacebook, FiTwitter } from "react-icons/fi";
import { TbBrandTelegram, TbBrandInstagram } from "react-icons/tb";
import { FaTiktok } from 'react-icons/fa'

import '../styles/socials.css'

const Socials = () => {
  const { ref: aRef, inView: aView} = useInView();
  const { ref: bRef, inView: bView} = useInView();
  const { ref: cRef, inView: cView} = useInView();
  const { ref: dRef, inView: dView} = useInView();
  const { ref: eRef, inView: eView} = useInView();
  const { ref: fRef, inView: fView} = useInView();
  return (
    <>
      <div className="social-handles">
        <div className="social cont">
          <div className="socials-left">
            <h2 className={"anihideLeft" + (aView ? " anishowRight" : " ")} style={{ "--i" : "3"}} ref={aRef}>A Network you can trust.</h2>
            <ul>
              <li className={"anihideIn" + (bView ? " anishowOut" : " ")} style={{ "--i" : "5"}} ref={bRef}><Link to='https://facebook.com'><FiFacebook /> </Link></li>
              <li className={"anihideIn" + (cView ? " anishowOut" : " ")} style={{ "--i" : "6"}} ref={cRef}><Link to='https://instagram.com'><TbBrandInstagram /></Link></li>
              <li className={"anihideIn" + (dView ? " anishowOut" : " ")} style={{ "--i" : "7"}} ref={dRef}><Link to='https://tiktok.com'><FaTiktok /></Link></li>
              <li className={"anihideIn" + (eView ? " anishowOut" : " ")} style={{ "--i" : "8"}} ref={eRef}><Link to='https://t.me'><TbBrandTelegram /></Link></li>
              <li className={"anihideIn" + (fView ? " anishowOut" : " ")} style={{ "--i" : "9"}} ref={fRef}><Link to='https://x.com'><FiTwitter /></Link></li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Socials