import React from 'react'
import TradingViewWidget from './TradingViewWidget'
import Trad from './Trad'

import '../styles/tradingview.css'
import AnalysisWidget from './AnalysisWidget'

const LiveTrading = () => {
  return (
    <>
    <div className="live-trader">
      <Trad />
      <TradingViewWidget />
      <AnalysisWidget />
    </div>
    </>
  )
}

export default LiveTrading