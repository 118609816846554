import React from 'react'
import Logo from '../../others/Logo';

const MobileNav = () => {
  return (
    <>
      <header className="heading">
        <Logo />
      </header>
    </>
  )
}

export default MobileNav