import AMERICANFLAG from "./AMERICANFLAG.png";
import BEACH from "./BEACH.png";
import BLACK_MONOGRAM from "./BLACK_MONOGRAM.png";
import DISCOVER_IT_CHP from "./DISCOVER_IT_CHP.png";
import FRENCHIE from "./FRENCHIE.png";
import IRIDESCENT from "./IRIDESCENT.png";
import IT_CHP_GARNET from "./IT_CHP_GARNET.png";
import IT_CHP_ROSEQUARTZ from "./IT_CHP_ROSEQUARTZ.png";
import IT_GOLD from "./IT_GOLD.png";
import IT_OCEAN from "./IT_OCEAN.png";
import IT_RASPBERRY from "./IT_RASPBERRY.png";
import KITTEN from "./KITTEN.png";
import MIXTAPE from "./MIXTAPE.png";
import NEWYORKSKYLINE from "./NEWYORKSKYLINE.png";
import PINKMONOGRAM from "./PINKMONOGRAM.png";
import PRIDE from "./PRIDE.png";
import STUDENT_FROGS from "./STUDENT_FROGS.png";
import STUDENT_SWIRLS from "./STUDENT_SWIRLS.png";
import YELLOWLAB from "./YELLOWLAB.png";


const IMGS = {
  americanflag: AMERICANFLAG,
  beach: BEACH,
  black_monogram: BLACK_MONOGRAM,
  discoverIt: DISCOVER_IT_CHP,
  frenchie: FRENCHIE,
  iridescent: IRIDESCENT,
  garnet: IT_CHP_GARNET,
  rosequartz: IT_CHP_ROSEQUARTZ,
  gold: IT_GOLD,
  ocean: IT_OCEAN,
  rasberry: IT_RASPBERRY,
  kitten: KITTEN,
  mixtape: MIXTAPE,
  nycsky: NEWYORKSKYLINE,
  pink: PINKMONOGRAM,
  pride: PRIDE,
  frogs: STUDENT_FROGS,
  swirls: STUDENT_SWIRLS,
  lab: YELLOWLAB,
};

export default IMGS;