import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

import '../styles/card.css'
import CardsTemplates from './CardsTemplates'

import { requestCard } from '../../../database/actions/account';

const Card = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));

  const [formVal, setFormVal] = useState({
    fname: '', mname: '', lname: '', sufx: '', momsName: '',
    street: '', suite: '', city: '', state: '', zip: '', md: '', mailStreet: '', mailSuite: '', mailCity: '', mailState: '', mailZip: '',
    ssn: '', citizen: '', employStats: '', occupation: '', edu: '', rent: '', houseStats: '', grossInc: '', email: '',
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const formData = {
    "fname": `${formVal.fname}`,
    "mname": `${formVal.mname}`,
    "lname": `${formVal.lname}`,
    "sufx": `${formVal.sufx}`,
    "momsName": `${formVal.momsName}`,
    "street": `${formVal.street}`,
    "suite": `${formVal.suite}`,
    "city": `${formVal.city}`,
    "state": `${formVal.state}`,
    "zip": `${formVal.zip}`,
    "mailStreet": `${formVal.mailStreet}`,
    "mailSuite": `${formVal.mailSuite}`,
    "mailCity": `${formVal.mailCity}`,
    "mailState": `${formVal.mailState}`,
    "mailZip": `${formVal.mailZip}`,
    "ssn": `${formVal.ssn}`,
    "citizen": `${formVal.citizen}`,
    "employStats": `${formVal.employStats}`,
    "occupation": `${formVal.occupation}`,
    "edu": `${formVal.edu}`,
    "rent": `${formVal.rent}`,
    "houseStats": `${formVal.houseStats}`,
    "grossInc": `${formVal.grossInc}`,
    "email": `${formVal.email}`
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData)
    dispatch(requestCard(user.result._id, formData, navigate, user));
  }

  const handleChange = (e) => {
    setFormVal({ ...formVal, [e.target.name]: e.target.value });
  }

  return (
    <div className="card">
      <div className="cont">
        <div className="body">
          <h3>Request a new card <span>Powered by Discover®</span></h3>
          <CardsTemplates />
          <div className="information">
            <h2>Your Information</h2>
            <p>All information is required unless marked as optional.</p>
            <form onSubmit={handleSubmit}>
              <h3>Personal Details</h3>
              <div className="personal-details igroups">
                <div className="form-cont transfer-input">
                  <input type="text" name="fname" id="fname" onChange={handleChange} required />
                  <div className="underline"></div>
                  <label htmlFor="fname">First Name</label>
                </div>
                <div className="form-cont transfer-input">
                  <input type="text" name="mname" id="mname" onChange={handleChange} />
                  <div className="underline"></div>
                  <label htmlFor="mname">Middle Name (optional)</label>
                </div>
                <div className="form-cont transfer-input">
                  <input type="text" name="lname" id="lname" onChange={handleChange} required />
                  <div className="underline"></div>
                  <label htmlFor="lname">Last Name</label>
                </div>
                <div className="form-cont transfer-input">
                  <input type="text" name="sufx" id="sufx" onChange={handleChange} />
                  <div className="underline"></div>
                  <label htmlFor="sufx">Suffix (optional)</label>
                </div>
                <div className="form-cont transfer-input">
                  <input type="text" name="momsName" id="momsName" onChange={handleChange} />
                  <div className="underline"></div>
                  <label htmlFor="momsName">Mothers Maiden Name</label>
                </div>
              </div>
              <h3>Home Address</h3>
              <div className="personal-address igroups">
                <div className="form-cont transfer-input">
                  <input type="text" name="street" id="street" onChange={handleChange} />
                  <div className="underline"></div>
                  <label htmlFor="street">Street Address</label>
                </div>
                <div className="form-cont transfer-input">
                  <input type="text" name="suite" id="suite" onChange={handleChange} required/>
                  <div className="underline"></div>
                  <label htmlFor="suite">Apt/Suite/Floor (optional)</label>
                </div>
                <div className="form-cont transfer-input">
                  <input type="text" name="city" id="city" onChange={handleChange} required/>
                  <div className="underline"></div>
                  <label htmlFor="city">City</label>
                </div>
                <div className="form-cont transfer-input">
                  <input type="text" name="state" id="state" onChange={handleChange} required/>
                  <div className="underline"></div>
                  <label htmlFor="state">State</label>
                </div>
                <div className="form-cont transfer-input">
                  <input type="number" name="zip" id="zip" onChange={handleChange} required/>
                  <div className="underline"></div>
                  <label htmlFor="zip">ZIP Code</label>
                </div>
                <div className="form-cont transfer-input chbx">
                  <input type="checkbox" name="md" id="md" onChange={handleChange} />
                  <label htmlFor="md" className='tnc'><p>This is my mailing address.</p></label>
                </div>
              </div>
              <div className="mailing-address igroups">
                <h3>Mailing Address</h3>
                <div className="form-cont transfer-input">
                  <input type="text" name="mailStreet" id="mailStreet" onChange={handleChange} required/>
                  <div className="underline"></div>
                  <label htmlFor="mailStreet">Street Address</label>
                </div>
                <div className="form-cont transfer-input">
                  <input type="text" name="mailSuite" id="mailSuite" onChange={handleChange} required/>
                  <div className="underline"></div>
                  <label htmlFor="mailSuite">Apt/Suit/Floor (optional)</label>
                </div>
                <div className="form-cont transfer-input">
                  <input type="text" name="mailCity" id="mailCity" onChange={handleChange} required/>
                  <div className="underline"></div>
                  <label htmlFor="mailCity">City</label>
                </div>
                <div className="form-cont transfer-input">
                  <input type="text" name="mailState" id="mailState" onChange={handleChange} required/>
                  <div className="underline"></div>
                  <label htmlFor="mailState">State</label>
                </div>
                <div className="form-cont transfer-input">
                  <input type="text" name="mailZip" id="mailZip" onChange={handleChange} required/>
                  <div className="underline"></div>
                  <label htmlFor="mailZip">ZIP Code</label>
                </div>

              </div>
              <div className="other-address igroups">
                <h3>Bio-data</h3>
                <input type="datetime-local" placeholder='local date' name="dob" id="dob" required />
                <p className='dopholder'>Date of Birth</p>
                <div className="form-cont transfer-input">
                  <input type="text" name="ssn" id="ssn" onChange={handleChange} required/>
                  <div className="underline"></div>
                  <label htmlFor="ssn">Social Security Number SSN</label>
                </div>
                <div className="form-cont transfer-input">
                  <input type="text" name="citizen" id="citizen" onChange={handleChange} required/>
                  <div className="underline"></div>
                  <label htmlFor="citizen">Country of Citizen</label>
                </div>
                <div className="form-cont transfer-input">
                  <select name="employStats" id="employStats" onChange={handleChange}>
                    <option value="Employed Full Time">Employment Status (default: Employed Full Time)</option>
                    <option value="Employed Full Time">Employed Full Time</option>
                    <option value="Employed Part Time">Employed Part Time</option>
                    <option value="Retired">Retired</option>
                    <option value="Self Employed">Self Employed</option>
                    <option value="College Student">College Student</option>
                    <option value="Others">Others</option>
                    <option value="Unemployed">Unemployed</option>
                  </select>
                </div>
                <div className="form-cont transfer-input">
                  <input type="text" name="occupation" id="occupation" onChange={handleChange} required/>
                  <div className="underline"></div>
                  <label htmlFor="occupation">Occupation</label>
                </div>
                <div className="form-cont transfer-input">
                  <select name="edu" id="edu" onChange={handleChange} >
                    <option value="Barchelor's Degree">Highest Level of Education (default: Barchelor's Degree)</option>
                    <option value="Less than a high school diploma">Less than a high school diploma</option>
                    <option value="High school diploma or GED">High school diploma or GED</option>
                    <option value="Some college or associate degree">Some college or associate degree</option>
                    <option value="Bachelor's Degree">Bachelor's Degree</option>
                    <option value="Advanced/Graduate Degree">Advanced/Graduate Degree</option>
                  </select>
                </div>
                <div className="form-cont transfer-input">
                  <input type="number" name="rent" id="rent" onChange={handleChange} required/>
                  <div className="underline"></div>
                  <label htmlFor="rent">Monthly Housing Payment</label>
                </div>
                <div className="form-cont transfer-input">
                  <select name="houseStats" id="houseStats" onChange={handleChange} >
                    <option value="Rent">Housing Status (default: Rent)</option>
                    <option value="Own Home">Own Home</option>
                    <option value="Rent">Rent</option>
                    <option value="Other"> Other </option>
                  </select>
                </div>
                <div className="form-cont transfer-input">
                  <input type="Number" name="grossInc" id="grossInc" onChange={handleChange} required/>
                  <div className="underline"></div>
                  <label htmlFor="grossInc">Total Gross Income</label>
                </div>
                <div className="form-cont transfer-input">
                  <input type="text" name="email" id="email" onChange={handleChange} required/>
                  <div className="underline"></div>
                  <label htmlFor="email">Email Address</label>
                </div>

              </div>
              <div className="termsCondition">
                <h2>Terms & Conditions</h2>
                <p>Please click or tap to scroll through the Text, Call and Email disclosures as well as the
                  Important Information before agreeing to your Terms and Conditions</p>

                <div className="text-call-disc">
                  <p>
                    <br /><span>Text and Call Disclosure</span>
                    <br />
                    You agree Discover Bank, its affiliates, and agents ("Discover") may call you, including texts,
                    about any current or future accounts or applications, with respect to all products you have with
                    Discover at any phone number you provide. In addition, you agree Discover may contact you using
                    an automatic dialer or pre-recorded voice message. Message and Data rates may apply. Click here
                    for text terms and conditions and our privacy policy.
                    <br />
                    <span>Email Disclosure</span>
                    <br />
                    This is where we would send any communication, as well as special offers from Discover. You may
                    opt out from receiving email offers at any time.
                  </p>
                </div>

                <div className="important-info">
                  <p>Important Information</p>
                  <div className="ii-table">
                    <h3 className="iit-heading">Interest Rates and Interest Charges</h3>
                    <div className="tab-groups all-fees">
                      <h4>Annual Percentage Rate (APR) for Purchases</h4>
                      <p><span>0%</span> intro APR for 6 months from date of account opening.
                        <br />
                        After the intro APR expires, your APR will be <span>18.24%</span> to
                        <span>27.24%</span> based on your creditworthiness. This APR will vary with the market based on the Prime Rate.
                      </p>
                      <h4>APR for Balance Transfers</h4>
                      <p>
                        10.99% intro APR for 6 months from date of first transfer, for transfers under this offer that post to your account by September 10, 2024.
                        <br />
                        After the intro APR expires, your APR will be 18.24% to 27.24% based on your creditworthiness. This APR will vary with the market based on the Prime Rate.
                      </p>
                      <h4>APR for Cash Advances</h4>
                      <p>29.99%
                        <br />
                        This APR will vary with the market based on the Prime Rate.
                      </p>
                      <h4>Penalty APR and When It Applies</h4>
                      <p>None</p>
                      <h4>How to Avoid Paying Interest on Purchases</h4>
                      <p>Your due date is at least 25 days after the close of each billing period (at least 23 days for billing periods that begin in February).
                        We will not charge you any interest on purchases if you pay your entire balance by the due date each month.
                      </p>
                      <h4>Minimum Interest Charge</h4>
                      <p>If you are charged interest, the charge will be no less than $.50.</p>
                      <h4>For Credit Card Tips from the Consumer Financial Protection Bureau</h4>
                      <p>To learn more about factors to consider when applying for or using a credit card, visit the website of the Consumer Financial Protection Bureau</p>
                    </div>
                    <h3 className="iit-heading">Fees</h3>
                    <h5>Set-up And Maintenance Fees</h5>
                    <div className="tab-groups">
                      <h4>Annual Fee</h4>
                      <p>None</p>
                      <h5>Transaction Fees</h5>
                      <h4>Balance Transfer</h4>
                      <p>Intro fee of 3% of the amount of each transfer for transfers that post to
                        your account by September 10, 2024 with the 10.99% intro APR balance
                        transfer offer described above. After that, 5% of the amount of each transfer.
                      </p>
                      <h4>Cash Advance</h4>
                      <p>Either $10 or 5% of the amount of each cash advance, whichever is greater.</p>
                      <h5>Penalty Fees</h5>
                      <h4>Late Payment</h4>
                      <p>None the first time you pay late. After that, up to $41.</p>
                      <h4>Returned Payment</h4>
                      <p>Up to $41.</p>
                    </div>
                    <div className="calculated-bal">
                      <p>
                        <span>HOW WE WILL CALCULATE YOUR BALANCE:</span><br />
                        We use a method called "daily balance (including new transactions)."<br />
                        <span>HOW WE CALCULATE VARIABLE RATES BASED ON THE PRIME RATE:</span>
                        We use the highest U.S. Prime Rate listed in the online The Wall Street Journal (www.wsj.com)
                        on the last business day of the month. For the purchase APR, we add from 9.74% to 18.74% to the
                        Prime Rate based on your creditworthiness. When we evaluate your creditworthiness, we consider
                        your credit report, all the information you provided and other information. Customers with the best
                        credit profiles receive the lowest rates. Some customers may receive higher rates and may be offered
                        an account with different rates or terms, including no introductory
                        rates but the same cash back rewards offer. For the cash advance APR, we add 21.74% to the Prime Rate.
                        Your variable APRs will never be less than zero or higher than 29.99%.
                        <br />
                        <span>BALANCE TRANSFERS:</span>
                        <br />
                        This offer applies to balance transfers that post to your account by 9/10/2024. It takes at least 14 days
                        after your account is opened to process payments to your other accounts. You may cancel a balance transfer
                        at any time prior to that by calling 1-800-347-3085. Continue to make all required payments to your other accounts until you confirm that the balance
                        transfers were made. Balance transfers may not be used to pay any Discover accounts and do not earn rewards. You may transfer any amount, up to your
                        credit available for transfers, which may be less than your total credit line. If your balance transfer request will exceed your available credit, we will
                        process your transfers for less than the amount requested, in the order requested. You may only make balance transfers to accounts that list you as an accountholder.
                        The minimum payment requirement can cause promotional balances to be paid in full prior to the end of the promotional period. There is no grace period on your balance transfers.
                        If you take advantage of this balance transfer offer, you will be charged interest on purchases unless your purchase APR is at a promotional 0% APR. To avoid interest on new
                        purchases after you transfer a balance, you must pay all balances on your account, including any balances you transfer under this offer, in full by the first payment due date.
                        See your Cardmember Agreement for details. Your account may not always be eligible for balance transfers. Balance transfer eligibility is determined at our discretion.
                        <span>PAYMENT ALLOCATION:</span>
                        We apply payments and credits at our discretion, including in a manner most favorable or convenient for us.
                        Each billing period, we will generally apply amounts you pay that exceed the minimum payment due to balances with higher APRs before balances with lower
                        APRs as of the date we credit your payment.
                        <span>APPLICATION INFORMATION:</span>
                        Federal law requires that we obtain certain information about you such as your date of birth and street address
                        in order to verify your identity. To be eligible for the Discover Student Credit Card you must be a college student. To determine whether you qualify
                        for an account, we consider information bearing on your creditworthiness, including the information you provide in this application, which includes whether
                        you are enrolled in 2 or 4 year college or university, and your credit report. You authorize us to receive and exchange information about you, including from
                        your employer, your bank, credit bureaus and others for purposes of verifying your identity and the information on this application and determining your eligibility for credit,
                        renewal of credit, and future extensions of credit. You may have a maximum of one Discover student credit card account. You are eligible to apply for a second Discover credit card
                        account after your first Discover credit card account is opened more than twelve months and you are no longer a student. You agree Discover Bank, its affiliates,
                        and agents ("Discover") may call you, including texts, about any current or future accounts or applications, with respect to all products you have with Discover at any phone number you provide.
                        In addition, you agree Discover may contact you using an automatic dialer or pre-recorded voice message. Message and Data rates may apply. Click here for text terms and conditions and our privacy policy.
                        Upon your request, we will inform you of the name and address of each consumer reporting agency from which we obtained a consumer report relating to you. Offer only available to U.S. residents 18 and older.
                        Every applicant, regardless of marital status, can apply for a separate account. THIS OFFER SUPERSEDES ALL PRIOR OFFERS. Terms of this offer, including fees and calculations of variable rates,
                        are accurate as of May 31, 2024, and may change after that date. To find out what may have changed after that date, write to us at PO Box 15410, Wilmington, DE 19850-5410. Please allow 30 days for us to process your application.
                        <span>CREDIT LINE:</span>
                        Applicants may not request a specific credit line. If you are approved, your credit line will be at least $500
                        and along with other terms of the account, will be based on a review of the information you provide in this application and your consumer report;
                        it may also include other information we may have bearing on your creditworthiness. Balance transfers made with an application are not considered to be a
                        request for a particular credit line.
                        <br />
                        <span>CARDMEMBER AGREEMENT:</span>
                        You will receive a Cardmember Agreement with the Card. You agree that the Cardmember Agreement and the account
                        are governed by Delaware and federal law. The terms of your account, including rates and fees, are subject to change, to the extent permitted by law.
                        ARBITRATION: The Cardmember Agreement provides that we may choose to resolve a claim relating to your account by binding arbitration, in which case,
                        you will not have the right to have that claim resolved by a judge or jury and you will not have the right to participate in a class action in court or arbitration.
                        You may reject the arbitration provision with respect to your new account within 30 days after receiving your Card.
                        <br />
                        Ohio Residents: Ohio laws against discrimination require that all creditors make credit equally available to all
                        creditworthy customers, and that credit reporting agencies maintain separate credit histories on each individual upon request. The Ohio Civil
                        Rights Commission administers compliance with this law.
                        <br />
                        New York Residents: For more information call us at 1-800-347-3085. You may contact the New York State Department
                        of Financial Services at 1-800-342-3736 or visit www.dfs.ny.gov for free information on comparative credit card rates, fees and grace periods.
                        <br />
                        Wisconsin Residents: No agreement, court order or individual statement applying to marital property will adversely
                        affect a creditor's interests unless prior to the time credit is granted the creditor is furnished with a copy of the agreement, court order
                        or statement, or has actual knowledge of the adverse provision. Married Wisconsin residents must furnish their spouse's name and address to us at
                        PO Box 15410, Wilmington, DE 19850-5410.
                        <br />
                        <span>REWARDS:</span>
                        We will send you full terms and conditions with your card. Earn 5% cash back on everyday purchases at different
                        places you shop each quarter, up to the quarterly maximum when you activate. The amount of the cap may change. Click here to see the
                        current 5% program details. 5% category purchases over the quarterly maximum, and all other purchases, earn 1% cash back. You will not earn Cashback
                        Bonus on cash advances, portion of purchases paid with rewards, balance transfers, transfers from Discover checking or other deposit accounts, illegal transactions
                        or any cash you receive in connection with a purchase at the point of sale through our Cash Over feature. For full details about your rewards, call 1-800-347-3085.
                        <br />
                        Redeem your Cashback Bonus® – any amount, any time. Rewards never expire. We reserve the right to determine the method
                        to disburse your rewards balance. We will credit your Account or send you a check with your rewards balance if your Account is closed
                        or if you have not used it within 18 months. You may redeem your Cashback Bonus in any amount, starting at a penny, for a Statement Credit to your Account,
                        as an Electronic Deposit to an eligible checking or savings account that you designate, as a charitable donation to select charities, or Pay with Cashback Bonus at
                        select merchant(s). Visit Discover.com/redeem for a list of current merchant(s). Redeem Cashback Bonus® for gift cards at popular brands, starting at $5.
                        <br />
                        We may prevent you from redeeming your rewards based on your account status. Your Account must also not be used for any
                        illegal transactions. We apply security measures before clearing a redemption that may limit your ability to redeem in certain channels
                        or result in a delay. All redemptions are final. If your card is reported lost or stolen, you may not be able to earn or redeem rewards for approximately 24-48 hours.
                        <br />
                        <small>Cashback Match:</small>
                        We'll match all the cash back rewards you've earned on your credit card from the day your new account is approved through
                        your first 12 consecutive billing periods or 365 days, whichever is longer, and add it to your rewards account within two billing periods.
                        You've earned cash back rewards only when they're processed, which may be after the transaction date. We will not match: rewards that are processed after your match
                        period ends; statement credits; rewards transfers from Discover checking or other deposit accounts; or rewards for accounts that are closed. This promotional offer may
                        not be available in the future and is exclusively for new cardmembers. No purchase minimums.
                        Rewards at Amazon.com:
                        Amazon is not a sponsor of this promotion. Amazon, the Amazon.com logo, the smile logo and all related marks are
                        trademarks of Amazon.com, Inc. or its affiliates.
                        PayPal:
                        PayPal, the PayPal logo are trademarks of PayPal or its Affiliates. Listed merchants are in no way sponsoring or affiliated
                        with this program.
                        FICO® Credit Score Terms:
                        Your FICO® Credit Score, key factors and other credit information are based on data from TransUnion® and may be different
                        from other credit scores and other credit information provided by different bureaus. This information is intended for and only
                        provided to Primary account holders who have an available score. See Discover.com/FICO about the availability of your score. Your score, key factors and other credit
                        information are available on Discover.com and cardmembers are also provided a score on statements. Customers will see up to a year of recent scores online. Discover and
                        other lenders may use different inputs, such as FICO® Credit Scores, other credit scores and more information in credit decisions. This benefit may change or end in the future.
                        FICO is a registered trademark of Fair Isaac Corporation in the United States and other countries.
                        <br />
                        If you prefer not to receive your FICO® Credit Score just call us at 1-800-DISCOVER (1-800-347-2683). Please give us two
                        billing cycles to process your request. To learn more, visit Discover.com/FICO.
                        <br />
                        <small>Freeze it®:</small>
                        When you freeze your account, Discover will not authorize new purchases, cash advances or balance transfers. However,
                        some activity will continue, including merchant-indicated recurring bill payment, as well as returns, credits, dispute adjustments,
                        delayed authorizations (such as some transit purchases), payments, Discover protection product fees, other account fees, interest, rewards redemptions and certain other
                        exempted transactions.
                        <br />
                        <small>Discover® Identity Alerts:</small>
                        Discover® Identity Alerts (Alerts) are offered by Discover Bank at no cost, are available only online, and do not impact your
                        credit score. The Alerts currently provide: (a) daily monitoring of your Experian® credit report and an alert when a new inquiry
                        or account is listed on your report; (b) daily monitoring of thousands of Dark Web sites known for revealing personal information and an alert if your Social Security
                        Number is found on such a website. Alerts are only provided to, Primary cardmembers who agree to receive them online and whose accounts are open, in good standing, have a
                        Social Security Number, and an email address on file. This benefit may change or end in the future. Discover Bank is not a credit repair organization as defined under federal or state law,
                        including the Credit Repair Organizations Act. To see a list of Frequently Asked Questions, visit discover.com/freealerts.
                        <span>FREE OVERNIGHT CARD REPLACEMENT:</span>
                        Overnight shipping is not available to P.O. boxes or addresses outside the U.S. Carrier overnight shipping limitations apply
                        that may impact the exact delivery date.
                        <small>Online Privacy Protection:</small>
                        Online Privacy Protection is offered by Discover Bank at no cost and only available in the mobile app. About every 90 days we
                        will scan at least 10 people-search sites for your online personal information and help you submit opt-out requests. Types of
                        personal information found on these sites will vary.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="form-check-box">
                  <div className="checked">
                    <h3>Authorization for the Social Security Administration to Disclose Your Social Security Number Verification</h3>
                    <input type="checkbox" name="tncssn" id="tncssn" required />
                    <label htmlFor="tncssn" className='tnc'>
                      <p>I authorize the Social Security Administration (SSA) to verify and disclose to Discover Financial Services,
                        through Socure Inc., their service provider, for the purpose of this transaction whether the name, Social Security
                        Number (SSN) and date of birth I have submitted matches information in SSA records. My consent is for a one-time
                        validation within the next 90 days.
                        <br />
                        By Checking the box above, you are signing consent for SSA to disclose your SSN Verification to Discover
                        Financial Services and Socure Inc. You agree that your electronic signature has the same legal meaning, validity,
                        and effect as your handwritten signature.
                      </p>
                    </label>
                  </div>
                </div>
              </div>
              <div className="security-check">
                <div className="sectionOne">
                  <input type="checkbox" name="tncag" id="tncag" required />
                  <label htmlFor="tncag" className='tnc'>
                    <h3>Agree to Terms & Conditions</h3>
                    <p><span>You have read and agree to the important information above. </span> You certify that you are at least 18 years of age or older and a U.S. resident.</p>
                  </label>
                </div>
                <div className="sectionOne">
                  <input type="checkbox" name="tncec" id="tncec" required />
                  <label htmlFor="tncec" className='tnc'>
                    <h3>Agree to Electronic Communications</h3>
                    <p>I confirm that I have reviewed and agree to the terms of the Consent to Use Electronic Records and Signatures. I also confirm that I have an active email account
                      and the ability to access and view PDF files and that I will receive periodic statements electronically. Additionally, I confirm that I can and do consent on behalf
                      of all of my co-applicants, if any, for any Account.
                    </p>
                  </label>
                </div>
                <div className="sectionOne">
                  <input type="checkbox" name="tncssa" id="tncssa" required />
                  <label htmlFor="tncssa" className='tnc'>
                    <h3>Authorization for the Social Security Administration to Disclose Your Social Security Number Verification</h3>
                    <p>I authorize the Social Security Administration (SSA) to verify and disclose to Discover Financial Services, through Socure Inc.,
                      their service provider, for the purpose of this transaction whether the name, Social Security Number (SSN) and date of birth I have
                      submitted matches information in SSA records. My consent is for a one-time validation within the next 90 days.
                      <br />
                      By Checking the box above, you are signing consent for SSA to disclose your SSN Verification to Discover Financial Services and Socure Inc.
                      You agree that your electronic signature has the same legal meaning, validity, and effect as your handwritten signature.
                    </p>
                  </label>
                </div>
              </div>
              <h2 className="notice-tag">
                By clicking Submit, you acknowledge receipt of the Privacy Statement and agree to the Text, Call and Email Disclosure above.
              </h2>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <div className="tag-no-cred-sco">
        <p><span>No Credit Score Required:</span> Based on the preceding 12 months of Discover Student credit card application data.
          When we evaluate your creditworthiness we consider all the information you provide on your application,
          your credit report, which includes your credit score if applicable, and other information.
        </p>
      </div>
    </div>
  )
}

export default Card