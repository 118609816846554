import React from 'react'
import {Link} from 'react-router-dom'
import { useInView } from 'react-intersection-observer'

import '../../styles/cta.css'

const CTA = () => {
  const { ref: aRef, inView: aView} = useInView();
  const { ref: bRef, inView: bView} = useInView();
  const { ref: cRef, inView: cView} = useInView();
  return (
    <>
    <div className="cta">
      <div className="cont">
        <h2 className={"anihideDown" + (aView ? " anishowUp" : " ")} style={{ "--i" : "2"}} ref={aRef}>Need Credit?</h2>
        <Link to={`/sign-in`} className={"hoved anihideVis" + (bView ? " anishowVis" : " ")} style={{ "--i" : "8"}} ref={bRef}>Yes ! I postulate</Link>
        <p className={"anihideIn" + (cView ? " anishowOut" : " ")} style={{ "--i" : "4"}} ref={cRef}>We have the solution to all your financial problems! Plus, it's free !</p>
      </div>
    </div>
    </>
  )
}

export default CTA