import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux'

const Welcome = () => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const dispatch = useDispatch();
    
    const logout = () => {
        dispatch({ type: 'LOGOUT' })
        setUser(null)
      }

      useEffect(() =>{
        logout();
      }, [])
  return (
    <>
    <div className="welcome-page">
        <div className="wlc-cont">
            <h2 className="afted">
                Account Succesfully Created
            </h2>
            <div className="info">
                <h3>What's Next?</h3>
                <p>You have been Logged out but your details are safe. <br />To filter out <span>bots</span>, <span>bugs</span> and online <span>scams</span> <br /></p>
                <p>We take extra precautions to verify our users. Not to worry your details are safe with us.</p>
                <p>Within the next 24hours an Pin will be sent to email provided to you.</p>
                <p>Keep thiis pin safe as you will need it for every sign-in</p>
                <h5>Thank You For Banking with Us</h5>
            </div>
        </div>
    </div>
    </>
  )
}

export default Welcome