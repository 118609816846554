import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment';

import '../styles/livechat.css'

import { getAccount, sendMSG, updateMsgCounterSupport } from '../../../database/actions/account';

const LiveChat = () => {
  const initialState = {
    msg: ''
  }

  const [formData, setFormData] = useState(initialState);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const { account, isLoading, isSending } = useSelector((state) => state.accounts);
  const [msgs, setMsgs] = useState(account?.liveChats)
  const [unread, setUnread] = useState('');
  const [current, setCurrent] = useState('');
  const msgRef = useRef()
  const dispatch = useDispatch();



  const msgDets = {
    "sender": `${account?.userName}`,
    "message": `${formData.msg}`
  }



  useEffect(() => {
    dispatch(getAccount(user.result._id));
  }, []);

  useEffect(() => {
    if (account) {
    setMsgs(account?.liveChats)
    msgRef.current.scrollIntoView({ behavior: 'smooth'})
    const prev = account?.logs?.support
    const msgs = account?.liveChats
    const sendersMsg = msgs.filter((item) => item.sender === "Support")
    const current = sendersMsg.length
    const unread = Number(current) - Number(prev)
    setUnread(unread)
    setCurrent(current)
  }
  }, [account])

  useEffect(() => {
    if(unread > 0){
    dispatch(updateMsgCounterSupport(current, user.result._id))
    }
  },[dispatch, unread, current, user.result._id])

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newMSGS = await dispatch(sendMSG(msgDets, user.result._id));
    setMsgs(newMSGS)
    setFormData({...formData, msg: '' })
    msgRef.current.scrollIntoView({ behavior: 'smooth'})
  }

  if (!user) return (
    <>
    <h4 className="mh1">
      Loading
    </h4>
    </>
  )

  if (isLoading) {
    return (
      <>
    <h4 className="mh1">
      Loading
    </h4>
    </>
    )
  }

  return (
    <>
      <div className="livechat">
        {/* <div className="heading-cont">
        <a href="mailto:admin@finelinesupport.online">admin@finelinesupport.online</a>
        </div> */}
        <div className="livechat-cont">
          <div className="messages">
            <ul>
              {
                msgs?.map((msg) => (
                  (!msg.message ) ? '' :
                  <li key={msg._id} className={((msg.sender === 'Support') ? ' msgRight' : ' msgLeft')}>
                    <h6>{msg.message}</h6>
                    <span>{moment(msg.date).fromNow()}{ }</span>
                  </li>
                ))
              }
              <div ref={msgRef} />
            </ul>
          </div>
          <form onSubmit={handleSubmit}>
            <input type="text" name='msg' id='msg' onChange={handleChange} />
            <button type="submit">{isSending ? "sending..." : "Send"}</button>
          </form>
        </div>
      </div>
    </>
  )
}

export default LiveChat