const authReducer = (state = {isLoading: false, authData: null }, action) => {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isLoading: true };
  case 'STOP_LOADING':
      return { ...state, isLoading: false };
    case "AUTH":
      localStorage.setItem('profile', JSON.stringify({...action?.data}));

      return { ...state, authData: action?.data };
    case "LOGOUT":
      localStorage.clear();

      return { ...state, authData: null };
    default:
      return state;
  }
};

export default authReducer;