import React from 'react'
import { useInView } from 'react-intersection-observer'

import '../../styles/insurance.css'

import { FaChartBar , FaMoneyBillTrendUp} from "react-icons/fa6";
import { GiHealthPotion } from "react-icons/gi";
import { BsHouseCheck } from "react-icons/bs";
import { BiSolidCarGarage } from "react-icons/bi";
import { FcDataProtection } from "react-icons/fc";

const Insurance = () => {
  const { ref: aRef, inView: aView} = useInView();
  const { ref: bRef, inView: bView} = useInView();
  const { ref: cRef, inView: cView} = useInView();
  const { ref: dRef, inView: dView} = useInView();
  const { ref: eRef, inView: eView} = useInView();
  const { ref: fRef, inView: fView} = useInView();
  const { ref: gRef, inView: gView} = useInView();
  const { ref: hRef, inView: hView} = useInView();
  return (
    <>
    <div className="insurance">
        <div className="cont">
            <h2 className={"afted anihideLeft" + (aView ? " anishowRight" : " ")} style={{ "--i" : "4"}} ref={aRef}>Our <br /> Insurance</h2>
            <p className={ "anihideRight" + (bView ? " anishowLeft" : " ")} style={{ "--i" : "5"}} ref={bRef}>Tomorrow is expected Today, transmit capital to your loved ones!</p>
            <ul>
                <li className={ "anihideIn" + (cView ? " anishowOut" : " ")} style={{ "--i" : "6"}} ref={cRef}>
                    <FaChartBar />
                    <div className="div">
                        <h3>Loan insurance</h3>
                        <p>Loan insurance is essential to protect your loved ones and the property you buy in case of a hard blow.</p>
                    </div>
                </li>
                <li className={ "anihideIn" + (dView ? " anishowOut" : " ")} style={{ "--i" : "6.5"}} ref={dRef}>
                    <GiHealthPotion />
                    <div className="div">
                        <h3>Life insurance</h3>
                        <p>Do you want to maintain your standard of living and that of your family in the event of death, work stoppage, job loss or disability?</p>
                    </div>
                </li>
                <li className={ "anihideIn" + (eView ? " anishowOut" : " ")} style={{ "--i" : "7"}} ref={eRef}>
                    <BsHouseCheck />
                    <div className="div">
                        <h3>Housing insurance</h3>
                        <p>Because your home needs to be protected from burglary or fire, this option is yours.</p>
                    </div>
                </li>
                <li className={ "anihideIn" + (fView ? " anishowOut" : " ")} style={{ "--i" : "7.5"}} ref={fRef}>
                    <BiSolidCarGarage />
                    <div className="div">
                        <h3>Transport</h3>
                        <p>Because every driver is different, our warranties and options have been designed to fit your needs.</p>
                    </div>
                </li>
                <li className={ "anihideIn" + (gView ? " anishowOut" : " ")} style={{ "--i" : "8"}} ref={gRef}>
                    <FcDataProtection />
                    <div className="div">
                        <h3>Optimal Protection</h3>
                        <p>Ensure your housing, your health, your transport equipment or other. It is a significant security.</p>
                    </div>
                </li>
                <li className={ "anihideIn" + (hView ? " anishowOut" : " ")} style={{ "--i" : "8.5"}} ref={hRef}>
                    <FaMoneyBillTrendUp />
                    <div className="div">
                        <h3>Financial security</h3>
                        <p>Because each heritage tells a unique story, yours, and because you have strong demands, that's what you need.</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    </>
  )
}

export default Insurance