import React from "react";
import { Outlet } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";

const Home = () => {

  return (
    <>
      <Header />
      <h3>home</h3>
      <Outlet />
      <Footer />
    </>
  );
};

export default Home;
