import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from "react-router-dom";


const Forml = ({ signin, signup, isSignUp, setIsSignUp }) => {
  const errState = {
    mail: '', pw: ' ', ax: '', err: '', px: '', un: ''
  }

  const initialState = {
    firstName: '', lastName: '', email: '', number: '', userName: '', dob: '', homeAddress: '',
    country: '', region: '', city: '',
    businessName: '', businessAddress: '', ein: '', businessType: '', businessActivity: '', businessEst: '', grossRevenue: '', totalEmployees: '',
    ssn: '', branch: '', accountType: '',
    password: '', confirmPassword: '', pin: ''

  }
  const { isLoading } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState(initialState);
  const [errMSG, setErrMSG] = useState(errState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (errMSG.err === `401`) {
      setErrMSG({ ...errMSG, mail: `User don't exist` })
    } else if (errMSG.err === `402`) {
      setErrMSG({ ...errMSG, pw: `incorect password` })
    } else if (errMSG.err === `403`) {
      setErrMSG({ ...errMSG, mail: `Email already exist` })
    } else if (errMSG.err === `404`) {
      setErrMSG({ ...errMSG, px: `Passwod don't match.` })
    } else if (errMSG.err === `405`) {
      setErrMSG({ ...errMSG, un: `Username already taken.` })
    }   else if (errMSG.err === `406`) {
      setErrMSG({ ...errMSG, un: `Incorect Pin.` })
    }  else if (errMSG.err === `500`) {
      setErrMSG({ ...errMSG, ax: `poor connection!!!` })
    } else {
      setErrMSG({ mail: ``, pw: ``, ax: ``, px: ``, un: `` })
    }
  }, [errMSG.err])

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSignUp) {
      dispatch(signup(formData, navigate, setErrMSG));
    } else if ((!isSignUp)) {
      dispatch(signin(formData, navigate, setErrMSG));
    }
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const switchMode = () => {
    setIsSignUp((prevIsSignUp) => !prevIsSignUp);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="filler">
        <h6>{errMSG.ax}</h6>
        {
          isSignUp && (
            <>
            <h3>Personal Details</h3>
                <input type="text" name="firstName" id="firstName" placeholder="First Name" onChange={handleChange} required />
                <input type="text" name="lastName" id="lastName" placeholder="Last Name" onChange={handleChange} required />
                <input type="text" name='userName' id='userName' placeholder="User Name" onChange={handleChange} required />
                <input type="datetime-local" placeholder='local date'  name="dob" id="dob" onChange={handleChange} required/>
                <p className='dopholder'>Date of Birth</p>
                <input type="text" name='homeAddress' id='homeAddress' placeholder="Home Address" onChange={handleChange} required />
                <h6> {errMSG.un} </h6>
            </>
          )
        }
          <input type="email" name="email" id="email" placeholder="Email" onChange={handleChange} required />
          <h6> {errMSG.mail} </h6>
          <input type="password" name="password" id="password" placeholder="Password" onChange={handleChange} required />
          <h6>{errMSG.pw}</h6>
          {
            !isSignUp &&
          <input type="password" name="pin" id="pin" placeholder="Pin" onChange={handleChange} required />
          }
          <h6>{errMSG.pw}</h6>
        {
          isSignUp &&
          <>
            <input type="password" name="confirmPassword" id="confirmPassword" placeholder="Confirm Password" onChange={handleChange} required />
            <h6> {errMSG.px} </h6>

            <h3>Business Details</h3>
            <input type="text" name="businessName" id="businessName" placeholder="Business Legal Name" onChange={handleChange} required />
            <input type="text" name="businessAddress" id="businessAddress" placeholder="Business Address" onChange={handleChange} required />
            <input type="text" name="ein" id="ein" placeholder="EIN/SSN For Sole Proprietorship" onChange={handleChange} required />
            <input type="text" name="businessType" id="businessType" placeholder="Organization/Business Type" onChange={handleChange} required />
            <input type="text" name="businessActivity" id="businessActivity" placeholder="Detailed Business Activity" onChange={handleChange} required />
            <input type="text" name="businessEst" id="businessEst" placeholder="Date of Establishment of Business" onChange={handleChange} required />
            <input type="text" name="grossRevenue" id="grossRevenue" placeholder="Gross Revenue for 12months" onChange={handleChange} required />
            <input type="text" name="totalEmployees" id="totalEmployees" placeholder="Number ofEmployees" onChange={handleChange} required />
            <input type="text" name="ssn" id="ssn" placeholder="SSN(Social Security Number)" onChange={handleChange} required />
            <select name="branch" id="branch" onChange={handleChange}>
                <option value="Ontario Branch">Branch (Default: Ontario) </option>
                <option value="Australia Branch">Australia Branch</option>
                <option value="UK Branch">UK Branch</option>
                <option value="Berlin">Berlin</option>
                <option value="Manila Phillipines">Manila Phillipines</option>
              </select>
            <select name="accountType" id="accountType" onChange={handleChange}>
                <option value="Checking">Account Type (Default: Checking) </option>
                <option value="Checking">Checking Account</option>
                <option value="Current">Current Account</option>
                <option value="Saving">Savings Account</option>
              </select>
            </>
        }
        {
          isSignUp && (
            <div className='tnc'>
              <input type="checkbox" name="tnc" id="tnc" required />
              <label htmlFor="tnc" className='tnc'> I agree with <Link to='/auth'> Privacy & Policy</Link>,<Link to='/auth'>Terms & Condition</Link></label>
            </div>
          )
        }
        <button type="submit">{isLoading ? "Logging in..." : (isSignUp ? 'Sign Up' : 'Sign In')} </button>
        <p onClick={switchMode}> {
          isSignUp ? 'Have an account? Sign In' : "Don't Have an account? Sign Up"
        }</p>
      </form>
    </>
  )
}

export default Forml