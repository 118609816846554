import React from 'react'
import { Link } from "react-router-dom";

import Logo from '../../others/Logo';

const DesktopNav = () => {
  return (
    <>
      <header className="headed laptop">
        <div className="lap-nav">
          <Logo />
          <ul className='main-menu'>
            <li><Link to={`/`}> Home </Link></li>
            <li><Link to={`/sign-in`}> Account </Link></li>
            <li><Link to={`/sign-in`}> Savings </Link></li>
            <li><Link to={`/sign-in`}> Insurance </Link></li>
            <li><Link to={`/contact-us`}> Contact US </Link></li>
          </ul>
        </div>

      </header>
    </>
  )
}

export default DesktopNav