import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "../styles/header.css";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";

const Header = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const navigate = useNavigate();

  useEffect(() => {
    if(user){
      navigate(`/${user?.result?._id}/dashboard`)
    }
  }, [user])
  return (
    <>
    <DesktopNav />
    <MobileNav />
    </>
  );
};

export default Header;
