import React, { useState} from 'react'
import { useSelector } from "react-redux";
import moment from 'moment';

import '../styles/transaction.css'

const Transactions = () => {
  const { account } = useSelector((state) => state.accounts);
  const [currentRept, setCurrentRept] = useState([]);

  console.log(currentRept)

  console.log(account);
  return (
    <>
      <div className="trans">
        <div className="trans-header">
          <h2>History</h2>
        </div>
        <div className="trans-container">
          <div className="transactions">
            <table className="transact">
              <thead>
                <tr>
                  <th>Date</th>
                  {/* <th>Transaction ID</th> */}
                  <th>Recipient Name</th>
                  <th>Recipient Bank</th>
                  <th>Account Number</th>
                  <th>Address</th>
                  <th>Amount</th>
                  <th>Alert Type</th>
                  <th>Charge</th>
                  <th>Total</th>
                  <th>Status</th>
                  <th>Details</th>
                </tr>
              </thead>
              {!account?.transaction?.all?.length ? "Loading..." : (
                <tbody>
                  {account.transaction.all.sort((a, b) => {
              return (
                new Date(b.date).valueOf() -
                new Date(a.date).valueOf()
              );
            }).map((option, index) => (
                    <tr key={index}>
                      <td data-label="Date">{moment(option?.date).format('L')}</td>
                      {/* <td className='idid' data-label="Transaction ID">{option?._id}</td> */}
                      <td data-label="Recipient Name">{option?.recName}</td>
                      <td data-label="Recipient Bank">{option?.recBank}</td>
                      <td data-label="Account Number">{option?.accNum}</td>
                      <td data-label="Address">{option?.address}</td>
                      <td className='Amount' data-label="Amount">${option?.amount}</td>
                      <td data-label="Alert Type">{option?.alertType}</td>
                      <td data-label="Charge">{option.charge}</td>
                      <td data-label="Total">${option.total}</td>
                      <td data-label="Status">{option.status}</td>
                      <td data-label="Details"><p onClick={() => setCurrentRept(option)} className="reptDets">Full Details</p></td>
                    </tr>
                  ))}
                </tbody>
              )
              }
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default Transactions