import React from 'react'

import '../styles/footer.css'
import ETA from './ETA';
import Links from './Links';
import Socials from './Socials';
import Copyright from './Copyright';

const Footer = () => {

  return (
    <>
      <div className="footed" >
        <div className="footed-cont">
        <ETA />
          <Links />
          <Socials />
          <Copyright />
        </div>
      </div>
    </>
  )
}

export default Footer