import React, { useState } from "react";
import {useDispatch} from "react-redux";
import { useNavigate, Outlet} from "react-router-dom";
import Footer from './Footer'
import Header from './Header'

const Index = () => {
  
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const [liveC, setLiveC] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    navigate('/');
    dispatch({ type: 'LOGOUT' })
    setUser(null)
  }

  console.log(user);
  return (
    <>
    <div className="index-bg">
    <Header liveC={liveC} setLiveC={setLiveC}/>
    <Outlet />
    <Footer user={user} logout={logout}/>
    </div>
    </>
  )
}

export default Index