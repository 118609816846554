import React from 'react'
import { useInView } from 'react-intersection-observer'

import '../../styles/simulation.css'
const Simulation = () => {
  const { ref: aRef, inView: aView} = useInView();
  const { ref: bRef, inView: bView} = useInView();
  const { ref: cRef, inView: cView} = useInView();
  return (
    <>
      <div className="simulation">
        <div className="cont">
          <div className={"simulator anihideLeft" + (aView ? " anishowRight" : " ")} style={{ "--i" : "4"}} ref={aRef}>
            <h3>Simulate your credit now</h3>
            <form action="#" className="filler">
              <input type="number" name="amount" id="amount" placeholder='Amount of credit' required />
              <input type="number" name="duration" id="amount" placeholder='Duration (in months)' required />
              <p>Interest rate applicable <span>3% per year</span></p>
              <button className="hoved">Evaluate</button>
              <h5 className="resetBut">Reset</h5>
            </form>
          </div>
          <div className={"result anihideRight" + (bView ? " anishowLeft" : " ")} style={{ "--i" : "6"}} ref={bRef}>
            <h3>Result</h3>
            <ul>
              <li>
                <p>Amount of credit</p>
                <h4>$$$,$$$</h4>
              </li>
              <li>
                <p>Monthly</p>
                <h4>$$$,$$$</h4>
              </li>
              <li>
                <p>Total Interests</p>
                <h4>$$$,$$$</h4>
              </li>
              <li>
                <p>Total amount to be refunded</p>
                <h4>$$$,$$$</h4>
              </li>
            </ul>
          </div>
          <div className={"bottled anihideDown" + (cView ? " anishowUp" : " ")} style={{ "--i" : "7.5"}} ref={cRef}>
            <p>For example, for a credit of €5,000 over 24 months, you pay 24 Monthlys €214.91 (excluding optional insurance). The total amount due is €5,157.75. The rate annual membership Global (APR) fixed is 3.00%.</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Simulation