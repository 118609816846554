import React, { useState } from 'react'

import IMGS from '../assets'

const CardsTemplates = () => {
  const [selectedImg, setSelectedImg] = useState(IMGS.discoverIt)
  const [selectedImgTag, setSelectedImgTag] = useState("discoverIt")

  return (
    <>
      <div className="card-select">
        <div className="card-view">
          <h2>Apply in minutes.</h2>
          <div className="card-img">
            <p style={{ "backgroundImage": `url(${selectedImg})` }}></p>
          </div>
        </div>
        <div className="cards-list">
          <h3>No credit score required to apply</h3>
          <p>Please choose your card design.</p>
          <ul>
              <li className={(selectedImgTag === 'discoverIt' ? "activeCard" : " ")} onClick={() => setSelectedImgTag("discoverIt")}><p style={{ "backgroundImage": `url(${IMGS.discoverIt})` }} onClick={() => setSelectedImg(IMGS.discoverIt)}></p></li>
              <li className={(selectedImgTag === 'americanflag' ? "activeCard" : " ")} onClick={() => setSelectedImgTag("americanflag")}><p style={{ "backgroundImage": `url(${IMGS.americanflag})` }} onClick={() => setSelectedImg(IMGS.americanflag) }></p></li>
              <li className={(selectedImgTag === 'beach' ? "activeCard" : " ")} onClick={() => setSelectedImgTag("beach")}><p style={{ "backgroundImage": `url(${IMGS.beach})` }} onClick={() => setSelectedImg(IMGS.beach) }></p></li>
              <li className={(selectedImgTag === 'monogram' ? "activeCard" : " ")} onClick={() => setSelectedImgTag("monogram")}><p style={{ "backgroundImage": `url(${IMGS.black_monogram})` }} onClick={() => setSelectedImg(IMGS.black_monogram)}></p></li>
              <li className={(selectedImgTag === 'frenchie' ? "activeCard" : " ")} onClick={() => setSelectedImgTag("frenchie")}><p style={{ "backgroundImage": `url(${IMGS.frenchie})` }} onClick={() => setSelectedImg(IMGS.frenchie)}></p></li>
              <li className={(selectedImgTag === 'frogs' ? "activeCard" : " ")} onClick={() => setSelectedImgTag("frogs")}><p style={{ "backgroundImage": `url(${IMGS.frogs})` }} onClick={() => setSelectedImg(IMGS.frogs)}></p></li>
              <li className={(selectedImgTag === 'garnet' ? "activeCard" : " ")} onClick={() => setSelectedImgTag("garnet")}><p style={{ "backgroundImage": `url(${IMGS.garnet})` }} onClick={() => setSelectedImg(IMGS.garnet)}></p></li>
              <li className={(selectedImgTag === 'gold' ? "activeCard" : " ")} onClick={() => setSelectedImgTag("gold")}><p style={{ "backgroundImage": `url(${IMGS.gold})` }} onClick={() => setSelectedImg(IMGS.gold)}></p></li>
              <li className={(selectedImgTag === 'iridescent' ? "activeCard" : " ")} onClick={() => setSelectedImgTag("iridescent")}><p style={{ "backgroundImage": `url(${IMGS.iridescent})` }} onClick={() => setSelectedImg(IMGS.iridescent)}></p></li>
              <li className={(selectedImgTag === 'kitten' ? "activeCard" : " ")} onClick={() => setSelectedImgTag("kitten")}><p style={{ "backgroundImage": `url(${IMGS.kitten})` }} onClick={() => setSelectedImg(IMGS.kitten)}></p></li>
              <li className={(selectedImgTag === 'lab' ? "activeCard" : " ")} onClick={() => setSelectedImgTag("lab")}><p style={{ "backgroundImage": `url(${IMGS.lab})` }} onClick={() => setSelectedImg(IMGS.lab)}></p></li>
              <li className={(selectedImgTag === 'mixtape' ? "activeCard" : " ")} onClick={() => setSelectedImgTag("mixtape")}><p style={{ "backgroundImage": `url(${IMGS.mixtape})` }} onClick={() => setSelectedImg(IMGS.mixtape)}></p></li>
              <li className={(selectedImgTag === 'nycsky' ? "activeCard" : " ")} onClick={() => setSelectedImgTag("nycsky")}><p style={{ "backgroundImage": `url(${IMGS.nycsky})` }} onClick={() => setSelectedImg(IMGS.nycsky)}></p></li>
              <li className={(selectedImgTag === 'ocean' ? "activeCard" : " ")} onClick={() => setSelectedImgTag("ocean")}><p style={{ "backgroundImage": `url(${IMGS.ocean})` }} onClick={() => setSelectedImg(IMGS.ocean)}></p></li>
              <li className={(selectedImgTag === 'pink' ? "activeCard" : " ")} onClick={() => setSelectedImgTag("pink")}><p style={{ "backgroundImage": `url(${IMGS.pink})` }} onClick={() => setSelectedImg(IMGS.pink)}></p></li>
              <li className={(selectedImgTag === 'pride' ? "activeCard" : " ")} onClick={() => setSelectedImgTag("pride")}><p style={{ "backgroundImage": `url(${IMGS.pride})` }} onClick={() => setSelectedImg(IMGS.pride)}></p></li>
              <li className={(selectedImgTag === 'rasberry' ? "activeCard" : " ")} onClick={() => setSelectedImgTag("rasberry")}><p style={{ "backgroundImage": `url(${IMGS.rasberry})` }} onClick={() => setSelectedImg(IMGS.rasberry)}></p></li>
              <li className={(selectedImgTag === 'rosequartz' ? "activeCard" : " ")} onClick={() => setSelectedImgTag("rosequartz")}><p style={{ "backgroundImage": `url(${IMGS.rosequartz})` }} onClick={() => setSelectedImg(IMGS.rosequartz)}></p></li>
              <li className={(selectedImgTag === 'swirls' ? "activeCard" : " ")} onClick={() => setSelectedImgTag("swirls")}><p style={{ "backgroundImage": `url(${IMGS.swirls})` }} onClick={() => setSelectedImg(IMGS.swirls)}></p></li>
          </ul>
        </div>
      </div>

    </>
  )
}

export default CardsTemplates