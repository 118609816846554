import React from 'react'
import Banners from './Banners'
import HotLine from './HotLine'
import Services from './Services'
import CTA from './CTA'
import Simulation from './Simulation'
import ContactSupport from './ContactSupport'
import Insurance from './Insurance'

const Main = () => {
  return (
    <>
    <Banners />
    <HotLine />
    <Services />
    <CTA />
    <Simulation />
    <ContactSupport />
    <Insurance />
    </>
  )
}

export default Main