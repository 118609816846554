import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import FileBase from 'react-file-base64'

import '../styles/profsettings.css'
 
import { updateProfile } from '../../../database/actions/account';
const ProfSettings = () => {
  
  const {accounts, account} = useSelector((state) => state.accounts);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const initialState = {
    firstName: '', lastName: '', email: '', number: '',
    userName: '', dob: '',
    homeAddress: '', country: '', region: '', city: '',
    id: '',
    businessName: '', businessAddress: '', ein: '',
    businessType: '', businessActivity: '', businessEst: '',
    grossRevenue: '',
    ssn: '', zip: '',
    password: '', confirmPassword: '',
    text: ''
  }
  const [formData, setFormData] = useState(initialState);

  const allData = {
    "firstName": `${formData.firstName}`, 
    "lastName": `${formData.lastName}`, 
    "email": `${formData.email}`, 
    "number": `${formData.number}`,
    "userName": `${formData.userName}`, 
    "dob": `${formData.dob}`,
    "homeAddress": `${formData.homeAddress}`, 
    "country": `${formData.country}`, 
    "region": `${formData.region}`, 
    "city": `${formData.city}`,
    "id": `${formData.id}`,
    "businessName": `${formData.businessName}`, 
    "businessAddress": `${formData.businessAddress}`, 
    "ein": `${formData.ein}`,
    "businessType": `${formData.businessType}`, 
    "businessActivity": `${formData.businessActivity}`, 
    "businessEst": `${formData.businessEst}`,
    "grossRevenue": `${formData.grossRevenue}`,
    "ssn": `${formData.ssn}`, 
    "zip": `${formData.zip}`,
    "password": `${formData.password}`,
    "confirmPassword": `${formData.password}`
  }

  useEffect(() => {
    if (account) setFormData({
      firstName : account.firstName,
      lastName: account.lastName,
      email: account.email,
      number: account.number,
      userName: account.userName,
      dob: account.dob,
      homeAddress: account.homeAddress,
      country: account.country,
      region: account.region,
      city: account.city,
      id: account.id,
      businessName: account.businessName,
      businessAddress: account.businessAddress,
      ein: account.ein,
      businessType: account.businessType,
      businessActivity: account.businessActivity,
      businessEst: account.businessEst,
      grossRevenue: account.grossRevenue,
      ssn: account.ssn,
      zip: account.zip,
    })
  }, [account])

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(formData.password !== formData.confirmPassword){
      setFormData({...formData, text: "Password don't match"})
    } else{
      setFormData({...formData})
      console.log(formData);
      dispatch(updateProfile(user.result._id, allData, navigate));
    }
  }
  return (
    <>
      <div className="psetting">
        <div className="set-heading"> <h2>Settings {formData.text} </h2></div>
        <div className="set-cont">
          <form onSubmit={handleSubmit}>
            <div className="ppix">
              <div className="pix" style={{ "backgroundImage": `url(${account?.id})`}}></div>
            </div>
            <ul className="names">
              <li>
                <input type="text" placeholder={account?.firstName} defaultValue={account?.firstName} name='firstName' id='firstName' required disabled />
                <div className="underline"></div>
                <label htmlFor="firstName">First Name</label>
              </li>
              <li>
                <input type="text" placeholder={account?.lastName} defaultValue={account?.lastName} name='lastName' id='lastName' required disabled />
                <div className="underline"></div>
                <label htmlFor="lastName">Last Name</label>
              </li>
              <li>
                <input type="email" placeholder={account?.email} defaultValue={account?.email} name='email' id='email' disabled />
                <div className="underline"></div>
                <label htmlFor="email">Email</label>
              </li>
              <li>
                <input type="text" placeholder={account?.userName} defaultValue={account?.userName} name='userName' id='userName' disabled />
                <div className="underline"></div>
                <label htmlFor="userName">Userame</label>
              </li>
              <li>
                <input type="datetime-local" placeholder={account?.dob} defaultValue={account?.dob} name='dob' id='dob' disabled />
                <div className="underline"></div>
                <label htmlFor="dob">D.O.B</label>
              </li>
            </ul>
            <ul className="address">
              <li>
                <input type="number" placeholder={account?.number} name='number' id='number' value={formData.number} onChange={handleChange} required />
                <div className="underline"></div>
                <label htmlFor="number">Mobile</label>
              </li>
              <li>
                <input type="text" placeholder={account?.homeAddress} name='homeAddress' id='homeAddress' value={formData.homeAddress} onChange={handleChange} required />
                <div className="underline"></div>
                <label htmlFor="homeAddress">Home Address</label>
              </li>
              <li>
                <input type="text" placeholder={account?.country} name='country' id='country' value={formData.country} onChange={handleChange} required />
                <div className="underline"></div>
                <label htmlFor="country">Country</label>
              </li>
              
              <li>
                <input type="text" placeholder={account?.region} name='region' id='region' value={formData.region} onChange={handleChange} />
                <div className="underline"></div>
                <label htmlFor="state">Region</label>
              </li>
              <li>
                <input type="text" placeholder={account?.city} name='city' id='city' value={formData.city} onChange={handleChange} required/>
                <div className="underline"></div>
                <label htmlFor="city">City</label>
              </li>
              <li>
                <input type="text" placeholder={account?.businessName} name='businessName' id='businessName' value={formData.businessName} onChange={handleChange} required/>
                <div className="underline"></div>
                <label htmlFor="businessName">Business Name</label>
              </li>
              <li>
                <input type="text" placeholder={account?.businessAddress} name='businessAddress' id='businessAddress' value={formData.businessAddress} onChange={handleChange} required/>
                <div className="underline"></div>
                <label htmlFor="businessAddress">Business Address</label>
              </li>
              <li>
                <input type="text" placeholder={account?.ein} name='ein' id='ein' value={formData.ein} onChange={handleChange} required/>
                <div className="underline"></div>
                <label htmlFor="ein">ein</label>
              </li>
              <li>
                <input type="text" placeholder={account?.businessType} name='businessType' id='businessType' value={formData.businessType} onChange={handleChange} required/>
                <div className="underline"></div>
                <label htmlFor="businessType">Business Type</label>
              </li>
              <li>
                <input type="text" placeholder={account?.businessActivity} name='businessActivity' id='businessActivity' value={formData.businessActivity} onChange={handleChange} required/>
                <div className="underline"></div>
                <label htmlFor="businessActivity">Business Activity</label>
              </li>
              <li>
                <input type="text" placeholder={account?.businessEst} name='businessEst' id='businessEst' value={formData.businessEst} onChange={handleChange} required/>
                <div className="underline"></div>
                <label htmlFor="businessEst">business Est. Date</label>
              </li>
              <li>
                <input type="text" placeholder={account?.grossRevenue} name='grossRevenue' id='grossRevenue' value={formData.grossRevenue} onChange={handleChange} required/>
                <div className="underline"></div>
                <label htmlFor="grossRevenue">Gross Revenue</label>
              </li>
              <li>
                <input type="text" placeholder={account?.totalEmployees} name='totalEmployees' id='totalEmployees' value={formData.totalEmployees} onChange={handleChange} required/>
                <div className="underline"></div>
                <label htmlFor="totalEmployees">Total Employees</label>
              </li>
              <li>
                <input type="text" placeholder={account?.ssn} name='ssn' id='ssn' value={formData.ssn} onChange={handleChange} required/>
                <div className="underline"></div>
                <label htmlFor="ssn">ssn</label>
              </li>
              <li>
                <input type="number" placeholder={account?.zip} name='zip' id='zip' value={formData.zip} onChange={handleChange} required/>
                <div className="underline"></div>
                <label htmlFor="zip">zip</label>
              </li>
              <li>
              <FileBase type="file" multiple={false} onDone={({ base64 }) => setFormData({ ...formData, id: base64 })} />
                <label htmlFor="id">Upload Passport</label>
              </li>
            </ul>
            <ul className="password">
              <li>
                <input type="password"  name='password' id='password' onChange={handleChange} required/>
                <div className="underline"></div>
                <label htmlFor="password">Password</label>
              </li>
              <li>
                <input type="password"  name='confirmPassword' id='confirmPassword' onChange={handleChange} required/>
                <div className="underline"></div>
                <label htmlFor="confirmPassword">Confirm Password</label>
              </li>
            </ul>
            <button type='submit'>Update</button>
          </form>
        </div>
      </div>
    </>
  )
}

export default ProfSettings